<template>
  <v-container text-xs-center fluid class="pa-0 ma-0">
    <v-container class="">
      <div class="d-flex justify-center text-center">
        <div>
          <h3>Download The GoDial Enterprise Team App</h3>
          <img
            :src="computeLogo"
            alt="GoDial"
            width="100%"
            style="max-width: 350px"
          />
          <p class="font-weight-thin">
            Your team needs to download GoDial Enterprise Team App to start
            dialing!
          </p>
        </div>
      </div>
    </v-container>
    <!-- <v-card class="primarygrad">
      <v-row class="pa-0 ma-0">
        <v-col
          align="center"
          class="white--text d-flex align-center justify-center"
          md="2"
          cols="12"
        >
          <div>
            <v-icon color="white" x-large>mdi-android</v-icon>
            <br />
            <h2>Android</h2>
          </div>
        </v-col>
        <v-col md="5" cols="12" align="center" class="pa-2 ma-0">
          <v-card class="fill-height rounded" dark>
            <h3 class="font-weight-regular title text-center">
              Playstore
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Estimated Call Duration Tracking
            </h4>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="7" align="left">
                <h4>How To Install</h4>
                <br />
                1.Open link in your android device <br />
                2.You can also search in Playstore<br />
                3.Install the app<br />
                4.Open GoDial Enterprise and login!<br />
                <br />
              </v-col>
              <v-col
                class="d-flex align-center justify-center "
                cols="12"
                md="5"
              >
                <qrcode-vue
                  value="https://play.google.com/store/apps/details?id=com.avifa.godialent"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row>
                <v-col class="px-1">
                  <v-btn
                    block
                    @click="
                      open(
                        'https://play.google.com/store/apps/details?id=com.avifa.godialent'
                      )
                    "
                    >Open Playstore</v-btn
                  >
                </v-col>
                <v-col class="px-1">
                  <v-btn
                    block
                    @click="
                      copy(
                        'https://play.google.com/store/apps/details?id=com.avifa.godialent'
                      )
                    "
                    >Copy Link</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="5" cols="12" class="pa-2 ma-0" align="center">
          <v-card class="fill-height rounded" dark>
            <h3 class="font-weight-regular title text-center">
              APK
            </h3>
            <h4 class="font-weight-thin subtitle text-center">
              Accurate Call Duration Tracking
            </h4>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="7" align="left">
                <h4>How To Install</h4>
                <br />
                1. Download the APK or Scan the QR<br />
                2. Allow App Installations from Unknown Sources
                <br />
                <v-icon @click="open('https://youtube.com/watch?v=RQy33AyrWWA')"
                  >help</v-icon
                ><br />
                3. Install the APK<br />
                4. Open GoDial Enterprise and login!<br />
              </v-col>
              <v-col
                class="d-flex align-center justify-center "
                cols="12"
                md="5"
              >
                <qrcode-vue :value="`${UIENDPOINT}/apks/${APPAPK}`" />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row>
                <v-col class="px-1">
                  <v-btn block @click="open(`/apks/${APPAPK}`)">Dowload</v-btn>
                </v-col>
                <v-col class="px-1">
                  <v-btn block @click="copy(`${UIENDPOINT}/apks/${APPAPK}`)"
                    >Copy Link</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card> -->

    <v-container class="pa-0 ma-0" fluid>
      <v-card color="primarygrad">
        <v-row align="center">
          <v-col align="center" class="white--text" md="2" cols="12">
            <v-icon color="white" x-large>mdi-android</v-icon>
            <br />
            <h2>Android</h2>
          </v-col>
          <v-col md="10" cols="12">
            <v-row class="text-center justify-center align-center">
              <v-col cols="12" md="4">
                <qrcode-vue
                  value="https://play.google.com/store/apps/details?id=com.avifa.godialent"
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-btn
                  dark
                  block
                  @click="
                    open(
                      'https://play.google.com/store/apps/details?id=com.avifa.godialent'
                    )
                  "
                  >Open Playstore</v-btn
                >
              </v-col>
              <v-col cols="6" md="4">
                <v-btn
                  dark
                  block
                  @click="
                    copy(
                      'https://play.google.com/store/apps/details?id=com.avifa.godialent'
                    )
                  "
                  >COPY LINK</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="pa-0 ma-0" fluid>
      <v-card color="primarygrad">
        <v-row align="center">
          <v-col align="center" class="white--text" md="2" cols="12">
            <v-icon color="white" x-large>mdi-apple</v-icon>
            <br />
            <h2>Apple</h2>
          </v-col>
          <v-col md="10" cols="12">
            <v-row class="text-center justify-center align-center">
              <v-col cols="12" md="4">
                <qrcode-vue
                  value="https://apps.apple.com/in/app/godial-enterprise-team-app/id1506959298"
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-btn
                  dark
                  block
                  @click="
                    open(
                      'https://apps.apple.com/in/app/godial-enterprise-team-app/id1506959298'
                    )
                  "
                  >Open Appstore</v-btn
                >
              </v-col>
              <v-col cols="6" md="4">
                <v-btn
                  dark
                  block
                  @click="
                    copy(
                      'https://apps.apple.com/in/app/godial-enterprise-team-app/id1506959298'
                    )
                  "
                  >COPY LINK</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="pa-0 ma-0" fluid>
      <v-card color="primarygrad">
        <v-row align="center">
          <v-col align="center" class="white--text" md="2" cols="12">
            <img class="pa-0 ma-0" src="../assets/windows.png" />
            <h2 class="ma-0 pa-0">
              <span style="padding-top: 14px"> Windows</span>
            </h2>
          </v-col>
          <v-col md="2"></v-col>
          <v-col md="8" cols="12">
            <v-layout row wrap>
              <v-flex
                xs7
                sm9
                md8
                style="text-align: center;
                 padding: 5px;"
              >
                <v-text-field
                  v-model="tokenWindows"
                  label="API Token"
                  outlined
                  readonly
                  solo-inverted
                  dense
                  style="padding: 8px 0;"
                ></v-text-field>
              </v-flex>

              <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px; ">
                <v-btn
                  style="margin-left:10px; margin-top:8px; color:primary; padding: 20px ;"
                  mt-5
                  dark
                  small
                  :href="`${tokenWindows}`"
                  download
                  >Download
                </v-btn>

                <!-- <v-btn
                  style="margin-left:10px; margin-top:8px; color:primary; padding: 20px ;"
                  mt-5
                  dark
                  small
                  @click="copy(tokenWindows)"
                  >COPY LINK
                </v-btn> -->
              </v-flex>
            </v-layout>
          </v-col>

          <v-snackbar
            v-model="snackbar"
            :bottom="true"
            :right="true"
            timeout="2000"
            >Copied!</v-snackbar
          >
        </v-row>
      </v-card>
    </v-container>

    <v-container class="pa-0 ma-0" fluid>
      <v-card color="primarygrad">
        <v-row align="center">
          <v-col align="center" class="white--text" md="2" cols="12">
            <h2>
              <v-icon color="white" style="font-size: 60px; margin-bottom: 5px"
                >mdi-apple</v-icon
              >
              <br />
              Mac
            </h2>
          </v-col>
          <v-col md="2"></v-col>
          <v-col md="8" cols="12">
            <v-layout row wrap>
              <v-flex
                xs7
                sm9
                md8
                style="text-align: center;
                 padding: 5px;"
              >
                <v-text-field
                  v-model="tokenMac"
                  label="API Token"
                  outlined
                  readonly
                  solo-inverted
                  dense
                  style="padding: 8px 0;"
                ></v-text-field>
              </v-flex>

              <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px; ">
                <v-btn
                  style="margin-left:10px; margin-top:8px; color:primary; padding: 20px ;"
                  mt-5
                  dark
                  small
                  :href="`${tokenMac}`"
                  download
                  >Download
                </v-btn>
                <!-- <v-btn
                  style="margin-left:10px; margin-top:8px; color:primary; padding: 20px ;"
                  mt-5
                  dark
                  small
                  @click="copy(tokenMac)"
                  >COPY LINK</v-btn
                > -->
              </v-flex>
            </v-layout>
          </v-col>

          <v-snackbar
            v-model="snackbar"
            :bottom="true"
            :right="true"
            timeout="2000"
            >Copied!</v-snackbar
          >
        </v-row>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" :bottom="true" :right="true" :timeout="2000"
      >Copied!</v-snackbar
    >
  </v-container>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      snackbar: false,
      tokenWindows: "https://avifainfotech.com/godial-desktop/Godial.exe",
      tokenMac: "https://avifainfotech.com/godial-desktop/Godial.dmg",
    };
  },
  computed: {
    APPAPK() {
      return this.$store.state.APPAPK;
    },
    UIENDPOINT() {
      return this.$store.state.UIENDPOINT;
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    computeLogo() {
      return require("../assets/browserphone.png");
    },
  },
  methods: {
    downloadFile(link) {
      // window.open(link, "_blank");
      let a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.download = link.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    copy(link) {
      const self = this;
      this.$copyText(link).then(
        function(e) {
          self.snackbar = true;
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    open(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.v-card {
  margin: 5px;
  padding: 1.2rem;
}
</style>
